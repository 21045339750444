import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["form", "label"]
	
	update(e) {
		var checkboxId = e.currentTarget.getAttribute("for");
		var checkBox   = document.getElementById(checkboxId);
		
		if (checkBox.checked) {
  		checkBox.checked = false;
  	} else {
			checkBox.checked = true;
	  }
		
		this.formTarget.submit();
	}
}